export const ENDPOINTS = {
  WHOAMI: '/auth/whoami',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  REFRESH: '/auth/refresh',
  USERS: '/users',
  TEACHERS: '/teachers',
  STUDENTS: '/students',
  APPLICATIONS: '/applications',
  QUESTIONS: '/questions',
};
