import { Container } from '@mui/material';
import { useGetApplicationsQuery } from 'store/api/applications';

export const AdminApplications = () => {
  const { data } = useGetApplicationsQuery();

  return (
    <Container>
      <h1>Applications</h1>
      <div className='flex flex-col gap-5 mt-5'>
        {data?.map(({ id, name, email, instagram, telegram, ip }) => (
          <ul key={id}>
            <li>{name}</li>
            <li>{email}</li>
            <li>{instagram}</li>
            <li>{telegram}</li>
            <li>{ip}</li>
          </ul>
        ))}
      </div>
    </Container>
  );
};
