import { LoadingButton } from '@mui/lab';
import { Box, Card, Link, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import logo from 'assets/images/logo.png';
import { ReactComponent as IconGoogle } from 'assets/svg/icon-google.svg';
import { config } from 'shared/config';
import { bgGradient } from 'theme/css';

import { LoginForm } from './components/LoginForm';

export const Login = () => {
  const theme = useTheme();

  const googleLogin = () => (window.location.href = config.GOOGLE_LOGIN_URL);

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
        }),
        height: 1,
      }}
    >
      <Link
        href='/'
        sx={{
          position: 'fixed',
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      >
        <img src={logo} alt='logo' width={40} height={40} />
      </Link>

      <Stack alignItems='center' justifyContent='center' sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant='h4'>Login to Hanna School</Typography>
          <Typography variant='body2' sx={{ mt: 2, mb: 5 }}>
            Don’t have an account?
            <Link href='/' variant='subtitle2' sx={{ ml: 0.5 }}>
              Get started
            </Link>
          </Typography>
          <LoginForm />
          <LoadingButton
            startIcon={<IconGoogle />}
            fullWidth
            size='large'
            variant='outlined'
            color='primary'
            onClick={googleLogin}
          >
            Continue with Google
          </LoadingButton>
        </Card>
      </Stack>
    </Box>
  );
};
