import { GoogleSignin } from 'modules/GoogleSignin';
import { TeacherDashboard } from 'modules/TeacherDashboard';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from 'routes/PrivateRoute';
import { Role } from 'shared/types';

import { AdminApplicationsRoute } from './admin/AdminApplicationsRoute';
import { AdminDashboardRoute } from './admin/AdminDashboardRoute';
import { AdminQuestionsRoute } from './admin/AdminQuestionsRoute';
import { AdminStudentsRoute } from './admin/AdminStudentsRoute';
import { AdminTeachersRoute } from './admin/AdminTeachersRoute';
import { AdminUsersRoute } from './admin/AdminUsersRoute';
import { appRoutes } from './appRoutes';
import { HomeRoute } from './HomeRoute';
import { LoginRoute } from './LoginRoute';
import { PublicRoute } from './PublicRoute';
import { TeacherStudentsRoute } from './teacher/TeacherStudentsRoute';

const adminRoutes: RouteObject[] = [
  {
    path: appRoutes.admin.dashboard(),
    element: <AdminDashboardRoute />,
  },
  {
    path: appRoutes.admin.users(),
    element: <AdminUsersRoute />,
  },
  {
    path: appRoutes.admin.teachers(),
    element: <AdminTeachersRoute />,
  },
  {
    path: appRoutes.admin.students(),
    element: <AdminStudentsRoute />,
  },
  {
    path: appRoutes.admin.applications(),
    element: <AdminApplicationsRoute />,
  },
  {
    path: appRoutes.admin.questions(),
    element: <AdminQuestionsRoute />,
  },
];

const teacherRoutes: RouteObject[] = [
  {
    path: appRoutes.teacher.dashboard(),
    element: <TeacherDashboard />,
  },
  {
    path: 'teacher/students',
    element: <TeacherStudentsRoute />,
  },
  {
    path: 'teacher/homeworks',
    element: <div>HOMEWORKS</div>,
  },
];

const studentsRoutes: RouteObject[] = [
  {
    path: appRoutes.student.dashboard(),
    element: <div>student's dashboard</div>,
  },
  {
    path: 'student/homeworks',
    element: <div>HOMEWORKS</div>,
  },
  {
    path: 'student/lessons',
    element: <div>LESSONS</div>,
  },
];

export const router = createBrowserRouter([
  {
    path: appRoutes.root(),
    element: <HomeRoute />,
  },
  {
    element: <PublicRoute />,
    children: [
      {
        path: appRoutes.login(),
        element: <LoginRoute />,
      },
      {
        path: appRoutes.oauth.google(),
        element: <GoogleSignin />,
      },
    ],
  },

  {
    element: <PrivateRoute role={Role.Admin} />,
    children: adminRoutes,
  },
  {
    element: <PrivateRoute role={Role.Teacher} />,
    children: teacherRoutes,
  },
  {
    element: <PrivateRoute role={Role.Student} />,
    children: studentsRoutes,
  },
  {
    path: '*',
    element: <div>404</div>,
  },
]);
