export const admin = {
  dashboard: () => '/admin/dashboard',
  users: () => '/admin/users',
  teachers: () => '/admin/teachers',
  students: () => '/admin/students',
  applications: () => '/admin/applications',
  questions: () => '/admin/questions',
};

export const teacher = {
  dashboard: () => '/teacher/dashboard',
  students: () => '/teacher/students',
  homeworks: () => '/teacher/homeworks',
};

export const student = {
  dashboard: () => '/student/dashboard',
  lessons: () => '/student/lessons',
};

export const oauth = {
  google: () => '/oauth/google',
};

export const privatePaths = [admin, teacher, student].flatMap((routes) => Object.values(routes).map((fn) => fn()));

export const appRoutes = {
  root: () => '/',
  login: () => '/login',
  oauth,
  admin,
  teacher,
  student,
};
