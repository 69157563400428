import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { Iconify } from 'components/Iconify';
import { useLogin } from 'hooks/useLogin';

import { SignInDivider } from '../SigninDivider';

export const LoginForm = () => {
  const {
    showPassword,
    onShowPassword,
    onSubmit,
    handleSubmit,
    emailInputRefCallback,
    restInputRegister,
    errors,
    register,
    isLoading,
    errorMessage,
  } = useLogin();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          {...restInputRegister}
          ref={emailInputRefCallback}
          name='email'
          label='Email address'
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <TextField
          {...register('password')}
          name='password'
          label='Password'
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={onShowPassword} edge='end'>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='flex-end' sx={{ my: 3 }}>
        <Link href='/' variant='subtitle2' underline='hover'>
          Forgot password?
        </Link>
      </Stack>
      <LoadingButton fullWidth size='large' type='submit' variant='contained' color='inherit' loading={isLoading}>
        Login
      </LoadingButton>
      <SignInDivider className='my-4' />
    </form>
  );
};
