import { ENDPOINTS } from 'shared/endpoints';
import type { Applications } from 'shared/types';

import { baseApi } from '../base';

export const applicationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApplications: build.query<Applications, void>({
      query: () => ({
        url: ENDPOINTS.APPLICATIONS,
      }),
      providesTags: ['Applications'],
    }),
  }),
});

export const { useGetApplicationsQuery } = applicationsApi;
