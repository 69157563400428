export type Nullable<T> = T | null;

export type Order = 'asc' | 'desc';

export type MapProperty<T> = (data: T) => string;

export interface HeadLabel {
  id: string;
  label: string;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  width?: string;
  minWidth?: string;
}

export interface Item {
  id: string;
  label: string;
}

export interface AuthBodyPayload {
  email: string;
  password: string;
}

export interface AbstractEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface AbstractEntityWithUUID extends AbstractEntity {
  uuid: string;
}

export enum Role {
  Student = 'student',
  Teacher = 'teacher',
  Admin = 'admin',
}

export type UserRole = Role.Admin | Role.Student | Role.Teacher;

export interface User extends AbstractEntityWithUUID {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  isActive: boolean;
}

export type Users = User[];

export type WithUser<T> = {
  user: User;
} & T;

export type FlatUser<T> = Omit<T, 'user' | 'createdAt' | 'updatedAt'> &
  Pick<User, 'email' | 'firstName' | 'lastName' | 'role' | 'createdAt' | 'updatedAt' | 'isActive'>;

export interface RootStudent extends AbstractEntityWithUUID {
  user: User;
  teachers: RootTeacherInStudent[];
  balance: number;
  lessons: [];
}
export type RootStudents = RootStudent[];

export type RootTeacherInStudent = Omit<RootTeacher, 'students'>;
export type RootStudentInTeacher = Omit<RootStudent, 'teachers'>;
export type TeacherInStudent = FlatUser<RootTeacherInStudent>;
export type TeachersInStudent = TeacherInStudent[];
export type StudentInTeacher = FlatUser<RootStudentInTeacher>;
export type StudentsInTeacher = StudentInTeacher[];

export type Student = Omit<FlatUser<RootStudent>, 'teachers'> & {
  teachers: TeachersInStudent;
};

export type Students = Student[];

export interface RootTeacher extends AbstractEntityWithUUID {
  user: User;
  students: RootStudentInTeacher[];
  categories: [];
  lessons: [];
}
export type RootTeachers = RootTeacher[];

export type Teacher = Omit<FlatUser<RootTeacher>, 'students'> & {
  students: StudentsInTeacher;
};
export type Teachers = Teacher[];

export interface Application extends AbstractEntityWithUUID {
  name: string;
  email: string;
  instagram: Nullable<string>;
  telegram: Nullable<string>;
  ip: Nullable<string>;
}

export type Applications = Application[];

export interface Question extends AbstractEntityWithUUID {
  name: string;
  question: string;
  instagram: Nullable<string>;
  telegram: Nullable<string>;
  ip: Nullable<string>;
}

export type Questions = Question[];
