import { ENDPOINTS } from 'shared/endpoints';
import type { Questions } from 'shared/types';

import { baseApi } from '../base';

export const questionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query<Questions, void>({
      query: () => ({
        url: ENDPOINTS.QUESTIONS,
      }),
      providesTags: ['Questions'],
    }),
  }),
});

export const { useGetQuestionsQuery } = questionsApi;
